/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Italic'), local('DMSans-Italic'), url(./assets/fonts/DMSans-Italic.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Italic'), local('DMSans-Italic'), url(./assets/fonts/DMSans-Italic.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('DM Sans Medium Italic'), local('DMSans-MediumItalic'), url(./assets/fonts/DMSans-MediumItalic.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('DM Sans Medium Italic'), local('DMSans-MediumItalic'), url(./assets/fonts/DMSans-MediumItalic.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('DM Sans Bold Italic'), local('DMSans-BoldItalic'), url(./assets/fonts/DMSans-BoldItalic.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('DM Sans Bold Italic'), local('DMSans-BoldItalic'), url(./assets/fonts/DMSans-BoldItalic.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Regular'), local('DMSans-Regular'), url(./assets/fonts/DMSans-Regular.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Regular'), local('DMSans-Regular'), url(./assets/fonts/DMSans-Regular.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('DM Sans Medium'), local('DMSans-Medium'), url(./assets/fonts/DMSans-Medium.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('DM Sans Medium'), local('DMSans-Medium'), url(./assets/fonts/DMSans-Medium.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('DM Sans Bold'), local('DMSans-Bold'), url(./assets/fonts/DMSans-Bold.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('DM Sans Bold'), local('DMSans-Bold'), url(./assets/fonts/DMSans-Bold.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'icomoon';
  src: url(./assets/fonts/icomoon.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bathtub:before {
  content: "\e91e";
}
.icon-balcony:before {
  content: "\e914";
}
.icon-bottled-water:before {
  content: "\e919";
}
.icon-nightclub:before {
  content: "\e91a";
}
.icon-poolside-bar:before {
  content: "\e91b";
}
.icon-toiletries:before {
  content: "\e91d";
}
.icon-zip-logo:before {
  content: "\e913";
}
.icon-hours:before {
  content: "\e911";
}
.icon-adult:before {
  content: "\e900";
}
.icon-agree:before {
  content: "\e9022";
}
.icon-bicycle:before {
  content: "\e90e";
}
.icon-bigpay-logo:before {
  content: "\e9002";
}
.icon-bundle-bag:before {
  content: "\e92b";
}
.icon-bundle-baggage-40kg:before {
  content: "\e9191";
}
.icon-bundle-baggage:before {
  content: "\e915";
}
.icon-bundle-change-time:before {
  content: "\e922";
}
.icon-bundle-charge:before {
  content: "\e926";
}
.icon-bundle-express-baggage:before {
  content: "\e92e";
}
.icon-bundle-fast-track:before {
  content: "\e92d";
}
.icon-bundle-flatbed:before {
  content: "\e927";
}
.icon-bundle-lounge:before {
  content: "\e92c";
}
.icon-bundle-meal:before {
  content: "\e923";
}
.icon-bundle-pillow:before {
  content: "\e928";
}
.icon-bundle-seat:before {
  content: "\e924";
}
.icon-bundle-standard-seat:before {
  content: "\e929";
}
.icon-bundle-xpress-baggage-40kg:before {
  content: "\e918";
}
.icon-bundle-xpress-baggage:before {
  content: "\e912";
}
.icon-bundle-xpress-boarding:before {
  content: "\e925";
}
.icon-bundle-xpress-checkin:before {
  content: "\e92a";
}
.icon-car-door:before {
  content: "\e9001";
}
.icon-child:before {
  content: "\e901";
}
.icon-credit-card:before {
  content: "\e902";
}
.icon-cricket-equipment:before {
  content: "\e909";
}
.icon-dairy:before {
  content: "\e906";
}
.icon-diving-equipment:before {
  content: "\e90a";
}
.icon-egg:before {
  content: "\e91c";
}
.icon-expand:before {
  content: "\e903";
}
.icon-flight-hotel:before {
  content: "\e9133";
}
.icon-flight-takeoff:before {
  content: "\e904";
}
.icon-golf-equipment:before {
  content: "\e90b";
}
.icon-home-insurance:before {
  content: "\e921";
}
.icon-icon_baggage_20kg_default:before {
  content: "\e9222";
}
.icon-icon_baggage_20kg_selected:before {
  content: "\e9233";
}
.icon-icon_baggage_25kg_default:before {
  content: "\e9243";
}
.icon-icon_baggage_25kg_selected:before {
  content: "\e9255";
}
.icon-icon_baggage_30kg_default:before {
  content: "\e9266";
}
.icon-icon_baggage_30kg_selected:before {
  content: "\e9277";
}
.icon-icon_baggage_40kg_default:before {
  content: "\e9288";
}
.icon-icon_baggage_40kg_selected:before {
  content: "\e9299";
}
.icon-icon_baggage_none_default:before {
  content: "\e92aa";
}
.icon-icon_baggage_none_selected:before {
  content: "\e92bb";
}
.icon-infant:before {
  content: "\e905";
}
.icon-island:before {
  content: "\e532";
}
.icon-landmark:before {
  content: "\e936";
}
.icon-mobile-check-in:before {
  content: "\e92f";
}
.icon-mushroom:before {
  content: "\e9300";
}
.icon-my-bookings:before {
  content: "\e931";
}
.icon-no-baggage:before {
  content: "\e907";
}
.icon-no-flight:before {
  content: "\e194";
}
.icon-no-seat:before {
  content: "\e930";
}
.icon-no-sport-equipment:before {
  content: "\e908";
}
.icon-nut:before {
  content: "\e916";
}
.icon-return-flight:before {
  content: "\e910";
}
.icon-seafood:before {
  content: "\e938";
}
.icon-sesame:before {
  content: "\e939";
}
.icon-skis-equipment:before {
  content: "\e90c";
}
.icon-soy:before {
  content: "\e93b";
}
.icon-sport-equipment:before {
  content: "\e90f";
}
.icon-surfing-equipment:before {
  content: "\e90d";
}
.icon-travel-delay:before {
  content: "\e93e";
}
.icon-wheat:before {
  content: "\e917";
}  
